import axios from 'axios';

export const getData = (token) => {
  return new Promise((resolve, reject) => {
    axios({
      url: '/screen',
      method: 'get',
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
};
