import { IconButton } from '@mui/material';
import React from 'react';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import instance from '../services/ws.services';

const lineStyle = {
  opacity: 0.2,
  border: '0.063rem solid #3F203A',
  height: 0,
};

const titleStyle = {
  color: '#3F203A',
};

const buttonStyle = {
  padding: 0,
  color: 'black',
};

const ListItem = ({ itemInfo }) => {
  const handleClick = (name, number) => {
    instance.channelSwitch({ name, number });
  };

  return (
    <div
      className='flex flex-row justify-between p-3 listitem'
      onClick={() => handleClick(itemInfo.title, itemInfo.number)}
    >
      <div className='w-1/3 thumbnail' style={{ backgroundImage: `url("${itemInfo.thumbnail}")` }}>
        {/* <img src={itemInfo.thumbnail} alt='' /> */}
      </div>

      <div className='flex-1 flex-col px-5 '>
        <div style={lineStyle}></div>
        <div className='flex flex-col justify-between h-full '>
          <p className='font-semibold item-title' style={titleStyle}>
            {itemInfo.title}
          </p>

          <div className='flex flex-row'>
            <p style={titleStyle}>Play</p>
            <IconButton style={buttonStyle}>
              <PlayArrowOutlinedIcon fontSize='medium' />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
