import { Skeleton } from '@mui/material';
import React from 'react';

const ListItemSkeleton = () => {
  return (
    <div className='flex flex-row justify-between p-3 listitem'>
      <div className='w-1/3 thumbnail '>
        <Skeleton variant='rectangular' width={'100%'} height={'11vh'} />
      </div>

      <div className='flex-1 flex-col px-5 '>
        <div className='flex flex-col justify-between h-full '>
          <Skeleton variant='text' width={'100%'} height={'3vh'} />

          <div className='flex flex-row  items-center'>
            <Skeleton
              variant='text'
              className='mr-2'
              width={50}
              height={'3vh'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemSkeleton;
