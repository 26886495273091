import React from 'react';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

const Header = () => {

  const logo = useSelector(({ app }) => app.logo);
  const loaded = useSelector(({ app }) => app.loaded);

  return (
    <div className={clsx('h-14 w-full flex justify-center items-center', loaded ? 'header': '')}>
      <div className='flex justify-center items-center h-full w-full'>
        {  loaded ?
              logo && <img alt='logo' src={logo} className='logo' />
          :
          <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
        }
      </div>
    </div>
  );
};

export default Header;
