import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ListPage from './pages/ListPage';
import NotFound from './pages/NotFound';
import { styled, createTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import instance from './services/ws.services';
import { setLogout } from './store/app';

var hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const Root = styled('div')((props) => ({
  '&': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  '& .header': props.header ? props.header : {},
  '& .logo': props.logo ? props.logo : {},
  '& .title': props.title ? props.title : {},
  '& .listcontainer': props.listcontainer ? props.listcontainer : {},
  '& .listitem': props.listitem ? props.listitem : {},
}));

function App() {
  const clientTheme = useSelector(({ app }) => app.theme);
  const token = useSelector(({ app }) => app.token);
  const dispatch = useDispatch();

  const [theme, setTheme] = useState(createTheme({}));

  const handleLogout = useCallback(() => {
    dispatch(setLogout());
    // eslint-disable-next-line
  }, []);

  const handleCheckInactive = () => {
    if (document[hidden]) {
      dispatch(setLogout());
    }
  };

  useEffect(() => {
    setTheme(createTheme(clientTheme.miu));
  }, [clientTheme.miu]);

  useEffect(() => {
    if (token) {
      instance.connect(token, handleLogout);
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    document.addEventListener(visibilityChange, handleCheckInactive, false);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Root {...clientTheme}>
        <Routes>
          <Route exact path='/' element={<ListPage />} />
          <Route path='*' element={<NotFound />} />
          <Route exact path='/error' element={<NotFound />} />
        </Routes>
      </Root>
    </ThemeProvider>
  );
}

export default App;
