import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';


const NotFound = () => {

  const redirectUrl = useSelector(({ app }) => app.redirectUrl);

  useEffect(()=>{
    if(redirectUrl){
      window.location.href = redirectUrl;
    }
  },[redirectUrl])


  return <></>
};

export default NotFound;
