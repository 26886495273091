import React, { useEffect } from 'react';
import Header from '../components/Header';
import Title from '../components/Title';
import List from '../components/List';
import { useSelector, useDispatch } from 'react-redux';
import { getInfo, setToken } from '../store/app';
import { useSearchParams } from 'react-router-dom';

function ListPage() {
  const [searchParams] = useSearchParams();
  const token = useSelector(({ app }) => app.token);
  const logout = useSelector(({ app }) => app.logout);
  const dispatch = useDispatch();

  useEffect(() => {
    if (logout === true) {
      const url = localStorage.getItem('redirectUrl');
      window.location.href = url;
    }

    dispatch(setToken(searchParams.get('token')));
    // eslint-disable-next-line
  }, [logout]);

  useEffect(() => {
    if (token) {
      dispatch(getInfo(token));
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <>
      <Header />
      <Title />
      <List />
    </>
  );
}

export default ListPage;
