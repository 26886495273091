import io from 'socket.io-client';

let socket = null;

class SocketService {
  connect = (token, onDisconnect) => {
    return new Promise((resolve, reject) => {
      socket = io(process.env.REACT_APP_WS_BASE_URL, {
        extraHeaders: { Authorization: token },
      });
      socket.on('connect', () => {
        resolve();
      });
      socket.on('disconnect', () => {
        console.log('i`m disconnected');
        onDisconnect();
      });
      socket.on('connect_error', () => {
        onDisconnect();
        reject();
      });
    });
  };

  disconnect = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  on = (name, callback) => {
    if (socket) {
      // socket.emit('subscribe', name);
      return socket.on(name, callback);
    }
  };

  channelSwitch = (object) => {
    if (this.isConnected) {
      socket.emit('channelSwitch', object);
    }
  };

  getInstance = () => {
    return socket;
  };

  isConnected = () => {
    return !!(socket && socket.connected);
  };
}

const instance = new SocketService();

export default instance;
