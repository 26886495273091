import { createSlice } from '@reduxjs/toolkit';
import { getData } from '../services/api.services';

export const getInfo = (token) => (dispatch) => {
  getData(token)
    .then((data) => {
      dispatch(setData(data));
    })
    .catch((error) => {
      if (error.response.status === 403) {
        dispatch(setLogout());
      }
    });
};

const initialState = {
  loaded: false,
  logout: false,
  token: '',
  logo: null,
  title: '',
  videos: [],
  redirectUrl: null,
  buttonText: null,
  theme: {
    miu: {
      palette: {
        primary: {
          main: '#3F203A',
        },
        secondary: {
          main: '#edf2ff',
        },
      },
    },
    header: {
      backgroundColor: '#3F203A',
    },
    logo: {},
    title: {
      color: '#3F203A',
    },
    listcontainer: {},
    listitem: {
      '& .thumbnail': {},
      '& .item-title': {},
    },
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setData: (state, action) => {
      state.logo = action.payload.logo;
      state.title = action.payload.title;
      state.theme = action.payload.theme;
      state.videos = action.payload.channels;
      state.redirectUrl = action.payload.redirectUrl;
      state.buttonText = action.payload.buttonText;
      state.loaded = true;
      localStorage.setItem('redirectUrl', action.payload.redirectUrl);
    },
    setLogout: (state, action) => {
      state.logout = true;
    },
  },
  extraReducers: (builder) => {},
});

export const { setToken, setData, setLogout } = appSlice.actions;

export default appSlice.reducer;
