import React from 'react';
import { useSelector } from 'react-redux';
import ListItem from './ListItem';
import { List } from '@mui/material';
import ListItemSkeleton from './ListItemSkeleton';
import clsx from 'clsx';

const skeletons = new Array(10).fill('skeleton');

const ListComponent = () => {
  const media = useSelector(({ app }) => app.videos);
  const loaded = useSelector(({ app }) => app.loaded);

  return (
    <div
      className={clsx(
        'relative flex-1 listcontainer ',
        loaded ? 'overflow-y-auto' : ''
      )}
    >
      <div className='absolute w-full h-full '>
        <List className='h-full w-full '>
          {loaded
            ? media.map((item) => <ListItem key={item.id} itemInfo={item} />)
            : skeletons.map(() => <ListItemSkeleton key={Math.random()} />)}
        </List>
      </div>
    </div>
  );
};

export default ListComponent;
