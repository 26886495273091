import { Skeleton, Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const Title = () => {
  const title = useSelector(({ app }) => app.title);
  const loaded = useSelector(({ app }) => app.loaded);
  const redirectUrl = useSelector(({ app }) => app.redirectUrl);
  const buttonText = useSelector(({ app }) => app.buttonText);

  const handleClick = () => {
    window.location.href = redirectUrl;
  };

  return (
    <>
      {loaded ? (
        Boolean(title) && (
          <div className='flex flex-col w-full'>
            <div className='flex h-14 w-full p-3 justify-center items-center title'>
              <p className='text-lg md:text-center'>{title}</p>
            </div>
            <div className='flex px-3'>
              <Button
                variant='outlined'
                style={{ borderRadius: 50 }}
                onClick={handleClick}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className='flex h-14 w-full p-3 justify-center items-center title'>
          <Skeleton variant='text' width={'80%'} />
        </div>
      )}
    </>
  );
};

export default Title;
